<template>
  <div>
    <v-layout wrap justify-center pt-6>
      <v-flex xs12 sm6 md4 lg3  pa-1>
        <div class="card" @click="showHello = !showHello">
          <div class="content" :class="{ flipped: showHello }">
            <div class="front">
              <v-layout wrap justify-center>
                <v-flex xs12>
                  <v-img
                    height="580px"
                    style="border-radius: 12px"
                    src="../assets/Images/temp1.jpeg"
                  ></v-img>
                </v-flex>
              </v-layout>
              <!-- Front content, you can add an image or other content here -->
            </div>
            <div class="back">
              <v-layout wrap>
                <v-flex style="font-size: 15px">
                  <span>
                    A few years ago, nearly 14,000 Amur falcons were hunted
                    every day in Pangti, a village in Wokha district of
                    Nagaland. Amur falcons are the world’s longest migrating
                    raptors. Enroute the arduous migration they visit and roost
                    in Nagaland in thousands. This is when they were harvested
                    for sale and consumption.
                    <br />
                    <br />

                    Today, the hunters have turned into the protectors of these
                    October visitors.
                    <br />
                    <br />
                    A comprehensive campaign to protect Amur Falcons was
                    launched in Wokha district which led to the village council
                    calling a ban on hunting falcons.
                    <br /><br />
                    Former hunters stay in the jungle guarding the birds as a
                    part of the ‘Protection Squad’, receiving an honorarium by
                    the state govt. and WTI. In addition, alternative community
                    support measures, such as chicken farms and grain
                    distribution, helped mitigate their financial losses.
                  </span>
                </v-flex>
              </v-layout>
            </div>
          </div>
        </div>
      </v-flex>

      <v-flex xs12 sm6 md4 lg3 pa-1>
        <div class="card" @click="showHello = !showHello">
          <div class="content" :class="{ flipped: showHello }">
            <div class="front">
              <v-layout wrap justify-center>
                <v-flex xs12>
                  <v-img
                    height="580px"
                    style="border-radius: 12px"
                    src="../assets/Images/temp1.jpeg"
                  ></v-img>
                </v-flex>
              </v-layout>
              <!-- Front content, you can add an image or other content here -->
            </div>
            <div class="back">
              <v-layout wrap>
                <v-flex style="font-size: 15px">
                  <span>
                    The broad gauge railway track passing through Rajaji
                    National Park had already killed 18 elephants in train-hit
                    accidents.

                    <br />
                    <br />
                    To prevent these heart-wrenching tragedies, a RAP assessed
                    the reason for elephants crossing the line, identified the
                    problem areas and implemented mitigation measures.
                    <br />
                    <br />

                    While the study found that elephants were crossing the
                    railway line to access water, it also suggested that
                    increased track visibility for train drivers and regular
                    patrols by forest guards could bring down the mortalities.
                    <br />
                    <br />
                    WTI and Uttarakhand Forest Department organised workshops
                    for train drivers and guards, cleared vegetation along the
                    tracks, and placed signboards on vulnerable stretches. This
                    was coupled with rigorous patrolling of railway tracks.
                    <br />
                    <br />
                    Result? Zero elephant mortality due to train hits for a
                    decade in Rajaji!
                  </span>
                </v-flex>
              </v-layout>
            </div>
          </div>
        </div>
      </v-flex>

      <v-flex xs12 sm6 md4 lg3 pa-1 >
        <div class="card" @click="showHello = !showHello">
          <div class="content" :class="{ flipped: showHello }">
            <div class="front">
              <v-layout wrap justify-center>
                <v-flex xs12>
                  <v-img
                    height="580px"
                    style="border-radius: 12px"
                    src="../assets/Images/temp1.jpeg"
                  ></v-img>
                </v-flex>
              </v-layout>
              <!-- Front content, you can add an image or other content here -->
            </div>
            <div class="back">
              <v-layout wrap>
                <v-flex style="font-size: 15px">
                  <span>
                    The Great Hornbill is a stunning bird
distinguished by its massive and vibrant
yellow beak. It is classified as a scheduled
species under the 1972 Wildlife Protection
Act. Hornbills were once extensively hunted
for their beaks, which were used in the
traditional headgear of the Nyishi tribesmen
from India&#39;s north-eastern state of Arunachal

Pradesh.

                    <br />
                    <br />
                    However, hunting practices have long ceased
within the community. Through a RAP, an
awareness campaign was launched to
educate the community about the bird&#39;s
importance to the forest ecosystem and the
legal prohibition against killing it.
                    <br />
                    <br />

                    As part of the project, artificial beaks were
introduced as an alternative to real beaks for
the traditional head cap, known as &#39;podum.&#39;
These artificial beaks gained popularity
among the Nyishi tribesmen to the extent
that local self-help groups were trained to

manufacture them.
                  </span>
                </v-flex>
              </v-layout>
            </div>
          </div>
        </div>
      </v-flex>
    </v-layout>
  </div>
</template>
  
  <script>
export default {
  data() {
    return {
      showHello: false,
    };
  },
};
</script>
  
  <style>
.card {
  perspective: 1000px;
  cursor: pointer;
  width: auto; /* Adjust the card width as needed */
  height: 580px; /* Adjust the card height as needed */
  /* margin: 10px;*/
}

.content {
  width: 100%;
  height: 100%;
  transition: transform 0.5s;
  transform-style: preserve-3d;
}

.card:hover .content {
  transform: rotateY(180deg);
}

.front,
.back {
  width: 100%;
  height: 100%;
  position: absolute;
  backface-visibility: hidden;
  border-radius: 10px;
}

.front {
  background: #ccc; /* You can customize the front background color */
}

.back {
  background: #000;
  color: #fff;
  transform: rotateY(180deg);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}
</style>
  