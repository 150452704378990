<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#005f32"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#005f32" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #ffffff">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #ffffff">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-start>
      <v-flex xs12>
        <v-layout wrap justify-center>
          <v-flex xs12>
            <v-img src="../assets/Images/main.jpg">
              <v-layout wrap justify-center pt-9 pb-9>
                <v-flex xs11 lg8>
                  <v-layout wrap justify-start>
                    <v-flex xs12 md6 pl-0 pl-md-7 class="popregular">
                      <v-card
                        color="rgba(255, 255, 255, 0.6)"
                        elevation="3"
                        v-if="firstCard"
                        class="animate2"
                        id="div2"
                      >
                        <v-layout wrap justify-center pa-3 pa-sm-0>
                          <v-flex xs10>
                            <v-layout wrap justify-center>
                              <v-flex xs10 pt-3 text-center>
                                <span
                                  style="
                                    font-size: 18px;
                                    font-weight: bold;
                                    color: #3d673a;
                                  "
                                >
                                  Wildlife in India is plagued by innumerable
                                  threats requiring an immediate response.
                                </span>
                              </v-flex>
                              <v-flex
                                style="font-size: 14px"
                                xs12
                                pt-2
                                text-center
                              >
                                <span>
                                  WTI’s 400+ Rapid Action Projects across 25
                                  states and 3 union territories have
                                  effectively addressed such wildlife
                                  emergencies impacting over 30 species of
                                  mammals, reptiles, birds and fishes.
                                </span>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                          <v-flex xs10 pa-3>
                            <a
                              href="https://www.globalgiving.org/donate/9916/wildlife-trust-of-india/"
                            >
                              <v-btn
                                class="popregular"
                                large
                                height="30px"
                                color="#ecb338"
                                style="text-transform: none; color: white"
                              >
                                Donate on GlobalGiving
                              </v-btn>
                            </a>
                          </v-flex>
                          <v-flex xs10>
                            <v-layout wrap>
                              <v-flex xs5 align-self-center
                                ><v-divider></v-divider
                              ></v-flex>
                              <v-flex xs2 class="popregular ml">Or</v-flex>
                              <v-flex xs5 align-self-center
                                ><v-divider></v-divider
                              ></v-flex>
                            </v-layout>
                          </v-flex>

                          <v-flex xs12 pt-3 class="popregular">
                            <span style="color: #2e2e2e; font-weight: bold"
                              >Donate Here</span
                            >
                          </v-flex>

                          <v-flex xs12>
                            <v-layout wrap pa-7 class="popregular">
                              <v-flex xs12 sm6 md12 lg6 pa-2>
                                <v-btn
                                  @click="donation = BTN1"
                                  height="40px"
                                  :style="{
                                    backgroundColor:
                                      donation === BTN1 ? 'white' : '#e27725',
                                    color:
                                      donation === BTN1 ? 'black' : 'white',
                                  }"
                                  dark
                                  block
                                  >{{ BTN1 }}</v-btn
                                >
                              </v-flex>
                              <v-flex xs12 sm6 md12 lg6 pa-2>
                                <v-btn
                                  @click="donation = BTN2"
                                  height="40px"
                                  :style="{
                                    backgroundColor:
                                      donation === BTN2 ? 'white' : '#e27725',
                                    color:
                                      donation === BTN2 ? 'black' : 'white',
                                  }"
                                  dark
                                  block
                                  >{{ BTN2 }}</v-btn
                                >
                              </v-flex>
                              <v-flex xs12 sm6 md12 lg6 pa-2>
                                <v-btn
                                  @click="donation = BTN3"
                                  height="40px"
                                  :style="{
                                    backgroundColor:
                                      donation === BTN3 ? 'white' : '#e27725',
                                    color:
                                      donation === BTN3 ? 'black' : 'white',
                                  }"
                                  dark
                                  block
                                  >{{ BTN3 }}</v-btn
                                >
                              </v-flex>
                              <v-flex xs12 sm6 md12 lg6 pa-2>
                                <!-- v-if="donation !== 'Any other amount'" -->
                                <span v-if="flag == 'text'">
                                  <v-btn
                                    @click="flagClick()"
                                    height="40px"
                                    :style="{
                                      backgroundColor: '#e27725',
                                      color: 'white',
                                    }"
                                    dark
                                    block
                                  >
                                    Other amount
                                  </v-btn>
                                </span>
                                <span v-if="flag == 'noText'">
                                  <v-text-field
                                    type="number"
                                    id="my_textbox"
                                    height="40px"
                                    solo
                                    flat
                                    dense
                                    v-model="donation"
                                    class="center"
                                  >
                                    <template v-slot:prepend-inner>
                                      <v-layout justify-center pt-3>
                                        <v-flex
                                          xs12
                                          class="align-self-center"
                                          style="border-right: 1px dotted grey"
                                        >
                                          <span
                                            style="
                                              padding-right: 2px;
                                              font-family: poppinsregular;
                                              font-size: 12px;
                                              color: #000;
                                            "
                                            >{{ Cname + sign }}</span
                                          >
                                        </v-flex>
                                      </v-layout>
                                    </template>
                                  </v-text-field>
                                </span>
                              </v-flex>
                            </v-layout>
                          </v-flex>

                          <v-flex xs12 sm6 pb-3>
                            <v-btn
                              @click="
                                (secondCard = true),
                                  (firstCard = false),
                                  login()
                              "
                              height="40px"
                              color="#e27725"
                              dark
                              style="font-size: 20px; text-transform: none"
                              block
                              >Continue
                              <v-icon>mdi-chevron-right</v-icon></v-btn
                            >
                          </v-flex>
                          <v-flex
                            class="popregular"
                            xs12
                            pt-3
                            pb-3
                            text-center
                            style="background-color: #f2f2f2"
                          >
                            <span><v-icon small>mdi-lock</v-icon> </span>
                            <span>Secure Donation</span>
                          </v-flex>
                        </v-layout>
                      </v-card>

                      <v-card elevation="3" v-if="secondCard">
                        <v-form ref="file">
                          <v-layout
                            wrap
                            justify-center
                            pa-3
                            class="animate"
                            id="div1"
                          >
                            <v-flex xs12>
                              <v-layout wrap style="background-color: #f2f2f2">
                                <v-flex
                                  xs1
                                  align-self-center
                                  style="background-color: #f2f2f2"
                                >
                                  <v-icon
                                    @click="
                                      (firstCard = true), (secondCard = false)
                                    "
                                    >mdi-chevron-left</v-icon
                                  >
                                </v-flex>
                                <v-flex
                                  xs10
                                  py-3
                                  style="
                                    background-color: #f2f2f2;
                                    margin-bottom: 1px solid grey;
                                  "
                                >
                                  <span class="popregular ml"
                                    >Add Your Information.</span
                                  >
                                </v-flex>
                                <v-flex xs1 py-3>
                                  <v-spacer></v-spacer>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                            <v-flex xs12 py-6 text-center style="color: grey">
                              <span class="popregular" style="font-size: 20px"
                                >Who's giving today?</span
                              >
                              <br />

                              <i>
                                <span class="popregular"
                                  >We’ll never share this information with
                                  anyone</span
                                ></i
                              >
                            </v-flex>

                            <v-flex xs12 px-5>
                              <v-text-field
                                class="center2"
                                type="number"
                                ref="amount"
                                color="#828282"
                                placeholder="Amount"
                                label="Amount"
                                :prepend-inner-icon="sign"
                                outlined
                                rounded
                                :rules="[rules.required]"
                                v-model="donation"
                              ></v-text-field>
                            </v-flex>
                            <v-flex xs12 px-5>
                              <v-text-field
                                ref="firstname"
                                color="#828282"
                                prepend-inner-icon="mdi-account"
                                label="First Name"
                                rounded
                                outlined
                                class="center2"
                                :rules="[rules.required]"
                                v-model="firstname"
                              ></v-text-field>
                            </v-flex>
                            <v-flex xs12 px-5>
                              <v-text-field
                                ref="lastname"
                                color="#828282"
                                prepend-inner-icon="mdi-account"
                                label="Last Name"
                                rounded
                                outlined
                                class="center2"
                                :rules="[rules.required]"
                                v-model="lastname"
                              ></v-text-field>
                            </v-flex>
                            <v-flex xs12 px-5>
                              <v-text-field
                                ref="email"
                                color="#828282"
                                prepend-inner-icon="mdi-email"
                                outlined
                                rounded
                                class="center2"
                                label="Email"
                                :rules="[rules.email]"
                                v-model="email"
                              ></v-text-field>
                            </v-flex>
                            <template v-if="taxExemption == true">
                              <v-flex xs12 px-5>
                                <v-text-field
                                  color="#828282"
                                  prepend-inner-icon="mdi-pencil"
                                  outlined
                                  rounded
                                  class="center2"
                                  label="Address"
                                  :rules="[rules.required]"
                                  v-model="Newaddress"
                                ></v-text-field>
                              </v-flex>
                              <v-flex xs12 px-5>
                                <v-text-field
                                  color="#828282"
                                  prepend-inner-icon="mdi-pen"
                                  outlined
                                  rounded
                                  class="center2"
                                  label="City"
                                  :rules="[rules.required]"
                                  v-model="Newcity"
                                ></v-text-field>
                              </v-flex>
                              <v-flex xs12 px-5>
                                <v-text-field
                                  color="#828282"
                                  prepend-inner-icon="mdi-pen"
                                  outlined
                                  rounded
                                  class="center2"
                                  label="Pincode"
                                  :rules="[rules.required]"
                                  v-model="Newpincode"
                                ></v-text-field>
                              </v-flex>
                              <v-flex xs12 px-5>
                                <v-text-field
                                  color="#828282"
                                  prepend-inner-icon="mdi-pen"
                                  outlined
                                  rounded
                                  class="center2"
                                  label="Pan Number"
                                  :rules="[rules.required]"
                                  v-model="Newpannumber"
                                ></v-text-field>
                              </v-flex>
                            </template>

                            <v-flex xs12 px-5 pt-2>
                              <v-checkbox
                                v-model="taxExemption"
                                hide-details=""
                                class="LBL"
                                :value="true"
                                label="I wish to claim tax exemption against this donation"
                              ></v-checkbox>
                            </v-flex>

                            <v-flex xs12 px-5>
                              <v-checkbox
                                v-model="isanonymous"
                                hide-details=""
                                class="LBL"
                                :value="true"
                                label="Make my donation anonymous "
                              ></v-checkbox>
                            </v-flex>

                            <v-flex xs12 px-5>
                              <v-checkbox
                                v-model="subscribe"
                                hide-details=""
                                class="LBL"
                                :value="true"
                                label="
                      Subscribe to our newsletter for inspiring stories from our
                      projects"
                              ></v-checkbox>
                            </v-flex>

                            <v-flex xs12 sm6 py-8>
                              <v-btn
                                @click="validate()"
                                height="60px"
                                color="#e27725"
                                dark
                                style="
                                  font-size: 20px;
                                  text-transform: none;
                                  font-family: poppinsregular;
                                "
                                block
                                >Donate Now
                              </v-btn>
                            </v-flex>

                            <v-flex
                              class="popregular"
                              xs12
                              py-5
                              text-center
                              style="background-color: #f2f2f2"
                            >
                              <span><v-icon small>mdi-lock</v-icon> </span>
                              <span>Secure Donation</span>
                            </v-flex>
                          </v-layout>
                        </v-form>
                      </v-card>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs11 lg2 pt-9 text-left>
                  <v-btn
                    block
                    color="#e27725"
                    @click="scrollToFeaturedSupport()"
                  >
                    <span class="popregular" style="color: white">
                      FEATURED SUPPORT
                    </span>
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-img>
          </v-flex>
          <v-flex xs12 style="background-color: #d3d3d3">
            <v-layout wrap justify-center>
              <v-flex xs11 lg8>
                <v-layout pt-5 pb-5 wrap justify-end>
                  <v-flex xs12 md2 lg3 pr-lg-3 pr-xs-0>
                    <a href="#faq-section">
                      <v-btn block color="#e27725">
                        <span class="popregular medium" style="color: white">
                          FAQ'S
                        </span>
                      </v-btn>
                    </a>
                  </v-flex>

                  <v-flex pt-lg-0 pt-4 pt-0 xs12 md2 lg3>
                    <v-dialog v-model="dialog" width="500">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          block
                          class="popregular medium"
                          color="#e27725"
                          dark
                          v-bind="attrs"
                          v-on="on"
                        >
                          EMAIL SUBSCRIPTION
                        </v-btn>
                      </template>

                      <v-card>
                        <v-card-title
                          class="text-h5 grey lighten-2 popregular medium"
                        >
                          Subscribe Now
                        </v-card-title>

                        <v-card-text>
                          <div id="mc_embed_signup">
                            <form
                              action="https://wti.us20.list-manage.com/subscribe/post?u=2c47e36b829020c293c520c3f&amp;id=e29f2715de&amp;f_id=009c0deaf0"
                              method="post"
                              id="mc-embedded-subscribe-form"
                              name="mc-embedded-subscribe-form"
                              class="validate"
                              target="_blank"
                            >
                              <div id="mc_embed_signup_scroll">
                                <h2>Subscribe to the WTI Newsletter</h2>
                                <div class="indicates-required">
                                  <span class="asterisk">*</span> indicates
                                  required
                                </div>
                                <div class="mc-field-group">
                                  <label for="mce-FNAME"
                                    >First Name
                                    <span class="asterisk">*</span></label
                                  ><input
                                    type="text"
                                    name="FNAME"
                                    class="required text"
                                    id="mce-FNAME"
                                    required=""
                                    value=""
                                  />
                                </div>
                                <div class="mc-field-group">
                                  <label for="mce-LNAME">Last Name </label
                                  ><input
                                    type="text"
                                    name="LNAME"
                                    class="text"
                                    id="mce-LNAME"
                                    value=""
                                  />
                                </div>
                                <div class="mc-field-group">
                                  <label for="mce-EMAIL"
                                    >Email Address
                                    <span class="asterisk">*</span></label
                                  ><input
                                    type="email"
                                    name="EMAIL"
                                    class="required email"
                                    id="mce-EMAIL"
                                    required=""
                                    value=""
                                  />
                                </div>
                                <div hidden="">
                                  <input
                                    type="hidden"
                                    name="tags"
                                    value="3995300"
                                  />
                                </div>
                                <div id="mce-responses" class="clear">
                                  <div
                                    class="response"
                                    id="mce-error-response"
                                    style="display: none"
                                  ></div>
                                  <div
                                    class="response"
                                    id="mce-success-response"
                                    style="display: none"
                                  ></div>
                                </div>
                                <div
                                  aria-hidden="true"
                                  style="position: absolute; left: -5000px"
                                >
                                  <input
                                    type="text"
                                    name="b_2c47e36b829020c293c520c3f_e29f2715de"
                                    tabindex="-1"
                                    value=""
                                  />
                                </div>
                                <div class="clear">
                                  <input
                                    type="submit"
                                    name="subscribe"
                                    id="mc-embedded-subscribe"
                                    class="button"
                                    value="Subscribe"
                                  />
                                </div>
                              </div>
                            </form>
                          </div>
                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="primary" text @click="dialog = false">
                            Close
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-layout wrap>
          <v-flex xs12>
            <v-layout wrap justify-center>
              <v-flex
                xs11
                pt-6
                text-left
                class="contentfont"
                style="font-size: 24px; font-weight: 600"
              >
                What are Rapid Action Projects?
              </v-flex>
              <v-flex xs11 pt-4 text-left class="contentfont">
                Rapid Action Projects (RAPs) represent one of WTI&#39;s earliest
                initiatives, falling within the Wild Aid or outreach arm. These
                projects offer swift, targeted support, both in terms of
                financial resources and technical expertise, to address wildlife
                emergencies and emerging conservation concerns. The overarching
                objective is to address nascent conservation issues and prevent
                them from evolving into bigger conservation challenges.
              </v-flex>

              <v-flex xs11 lg11 pt-4>
                <iframe
                  :height="
                    $vuetify.breakpoint.name == 'xs'
                      ? '200px'
                      : $vuetify.breakpoint.name == 'sm'
                      ? '400px'
                      : $vuetify.breakpoint.name == 'md'
                      ? '300px'
                      : $vuetify.breakpoint.name == 'xl'
                      ? '500px'
                      : $vuetify.breakpoint.name == 'lg'
                      ? '440px'
                      : '440px'
                  "
                  :width="
                    $vuetify.breakpoint.name == 'xs'
                      ? 'auto'
                      : $vuetify.breakpoint.name == 'sm'
                      ? '590px'
                      : $vuetify.breakpoint.name == 'md'
                      ? '450px'
                      : $vuetify.breakpoint.name == 'xl'
                      ? '1000px'
                      : $vuetify.breakpoint.name == 'lg'
                      ? '680px'
                      : '680px'
                  "
                  src="https://www.youtube.com/embed/MmMlgKTM6Aw"
                  frameborder="1"
                  allowfullscreen
                  ng-show="showvideo"
                ></iframe>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-center>
              <v-flex xs11>
                <RapidAction />
              </v-flex>
            </v-layout>

            <v-layout wrap justify-center>
              <v-flex
                xs11
                pt-14
                text-left
                class="contentfont"
                style="font-size: 24px; font-weight: 600"
              >
                Meet the implementers
              </v-flex>
              <v-flex xs11 pt-4 text-left class="contentfont">
                Sometimes, dedicated individuals or local agencies have the
                solutions to urgent conservation problems but lack the resources
                or expertise to act swiftly. RAPs step in to empower them,
                offering support to organisations and individuals with proven
                credibility. Let’s meet some these implementers who are making a
                difference.
              </v-flex>
            </v-layout>

            <v-layout wrap pt-12 justify-center>
              <v-flex xs11>
                <v-layout wrap justify-center>
                  <v-flex xs11 lg4 pl-4>
                    <v-layout wrap justify-center>
                      <v-flex xs12>
                        <v-layout wrap justify-center>
                          <v-flex xs10>
                            <v-img src="../assets/Images/lionorg.jpeg"> </v-img>
                          </v-flex>
                        </v-layout>
                        <v-card class="popregular">
                          <v-layout wrap justify-center>
                            <v-flex xs12>
                              <v-expansion-panels flat>
                                <v-expansion-panel>
                                  <v-layout wrap justify-center>
                                    <v-flex xs11 pt-3 text-center>
                                      <span style="font-weight: bold"
                                        >Ex-Gratia support to Mahesh Soni
                                      </span>
                                    </v-flex>
                                    <v-flex pt-4 xs8 class="subheading">
                                      Who is Mahesh Soni?
                                    </v-flex>
                                    <v-flex xs1 pt-1>
                                      <v-expansion-panel-header>
                                      </v-expansion-panel-header>
                                    </v-flex>
                                  </v-layout>
                                  <v-expansion-panel-content>
                                    <v-layout wrap justify-center>
                                      <v-flex xs12 class="contentfont">
                                        Mahesh Soni serves as a Forester in the
                                        Betul Division of Maharashtra. He is 61
                                        and due for retirement next year.
                                      </v-flex>
                                    </v-layout>
                                  </v-expansion-panel-content>

                                  <v-expansion-panel-content class="subheading">
                                    What happened to Mahesh?
                                  </v-expansion-panel-content>
                                  <v-expansion-panel-content
                                    class="contentfont"
                                  >
                                    On August 9, 2023, during his routine forest
                                    patrol, Mahesh got into a perilous situation
                                    encountering a wood smuggler armed with an
                                    axe. In his defense, Mahesh only had a
                                    stick. The situation quickly escalated and
                                    the smuggler struck Mahesh with the blunt
                                    end of his axe, leaving him with a severe
                                    chest injury and 10 days of hospitalization.
                                  </v-expansion-panel-content>
                                  <v-expansion-panel-content
                                    class="contentfont"
                                  >
                                    Swift intervention by Forest staff saved his
                                    life, and WTI stepped in to cover his
                                    medical expenses.
                                  </v-expansion-panel-content>

                                  <v-expansion-panel-content
                                    class="contentfont"
                                  >
                                    It was a close call, and he remarked, 'Had
                                    the assailant used the sharp end of the axe,
                                    I would have died.'
                                  </v-expansion-panel-content>
                                  <v-expansion-panel-content class="subheading">
                                    Back to Work
                                  </v-expansion-panel-content>

                                  <v-expansion-panel-content
                                    class="contentfont"
                                  >
                                    Mahesh has recovered and is back to
                                    safeguarding the forests he loves.
                                  </v-expansion-panel-content>
                                </v-expansion-panel>
                              </v-expansion-panels>
                            </v-flex>
                          </v-layout>
                        </v-card>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex pt-5 pt-lg-0 xs11 lg4 pl-4>
                    <v-layout wrap justify-center>
                      <v-flex xs12>
                        <v-layout wrap justify-center>
                          <v-flex xs10>
                            <v-img src="../assets/Images/lionorg.jpeg"> </v-img>
                          </v-flex>
                        </v-layout>
                        <v-card class="popregular">
                          <v-layout wrap justify-center>
                            <v-flex xs12>
                              <v-expansion-panels flat>
                                <v-expansion-panel>
                                  <v-layout wrap justify-center>
                                    <v-flex xs11 pt-3 text-center>
                                      <span style="font-weight: bold"
                                        >Ex-Gratia support to Tilak Singh
                                      </span>
                                    </v-flex>
                                    <v-flex pt-4 xs8 class="subheading">
                                      Who is Tilak Singh?
                                    </v-flex>
                                    <v-flex xs1 pt-1>
                                      <v-expansion-panel-header>
                                      </v-expansion-panel-header>
                                    </v-flex>
                                  </v-layout>
                                  <v-expansion-panel-content>
                                    <v-layout wrap justify-center>
                                      <v-flex xs12 class="contentfont">
                                        Tilak Singh is a 49-year-old Forest
                                        Guard serving at the Betul Division of
                                        Maharashtra.
                                      </v-flex>
                                    </v-layout>
                                  </v-expansion-panel-content>

                                  <v-expansion-panel-content class="subheading">
                                    What happened to Tilak?
                                  </v-expansion-panel-content>
                                  <v-expansion-panel-content
                                    class="contentfont"
                                  >
                                    Following a tip-off from an informant, a
                                    forest department team, including Tilak
                                    Singh, set out to nab teak wood smugglers on
                                    19 November 2022. When confronted, the
                                    smugglers, in a desperate attempt to escape,
                                    struck Tilak with their speeding vehicle,
                                    causing him a severe head injury.
                                  </v-expansion-panel-content>

                                  <v-expansion-panel-content
                                    class="contentfont"
                                  >
                                    He was rushed to Paratwada Hospital by the
                                    forest department and later transferred to
                                    Amravati Hospital. WTI’s immediate ex-gratia
                                    support eased his medical bills
                                  </v-expansion-panel-content>

                                  <v-expansion-panel-content class="subheading">
                                    Back to Work
                                  </v-expansion-panel-content>

                                  <v-expansion-panel-content
                                    class="contentfont"
                                  >
                                    Tilak expressed gratitude for the timely
                                    assistance allowing him to focus on a swift
                                    recovery and return to his service.
                                  </v-expansion-panel-content>
                                </v-expansion-panel>
                              </v-expansion-panels>
                            </v-flex>
                          </v-layout>
                        </v-card>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex pt-5 pt-lg-0 xs11 lg4 pl-4>
                    <v-layout wrap justify-center>
                      <v-flex xs12>
                        <v-layout wrap justify-center>
                          <v-flex xs10>
                            <v-img src="../assets/Images/lionorg.jpeg"> </v-img>
                          </v-flex>
                        </v-layout>
                        <v-card class="popregular">
                          <v-layout wrap justify-center>
                            <v-flex xs12>
                              <v-expansion-panels flat>
                                <v-expansion-panel>
                                  <v-layout wrap justify-center>
                                    <v-flex
                                      xs11
                                      pt-3
                                      text-center
                                      style="font-weight: bold"
                                    >
                                      <span
                                        >Ex-Gratia support to Yadunandan
                                      </span>
                                    </v-flex>
                                    <v-flex pt-4 xs9 class="subheading">
                                      Who is Yadunandan Yadav?
                                    </v-flex>
                                    <v-flex xs1 pt-1>
                                      <v-expansion-panel-header>
                                      </v-expansion-panel-header>
                                    </v-flex>
                                  </v-layout>
                                  <v-expansion-panel-content>
                                    <v-layout wrap justify-center>
                                      <v-flex xs12 class="contentfont">
                                        In 1985, Yadunandan joined as a Peon on
                                        compassionate grounds after his father,
                                        serving as a Range Forest Officer (RFO),
                                        tragically lost his life on duty. His
                                        dedication resulted in his promotion to
                                        a full-time forester eventually.
                                      </v-flex>
                                    </v-layout>
                                  </v-expansion-panel-content>

                                  <v-expansion-panel-content class="subheading">
                                    What happened to Yadunandan?
                                  </v-expansion-panel-content>
                                  <v-expansion-panel-content
                                    class="contentfont"
                                  >
                                    On April 12, 2023, a team of 6-7 forest
                                    staff, including Yadunandan, was activated
                                    following information received on the
                                    movement of tree-fellers along the
                                    Khomai-Andherbadi marg at the MP-Maharashtra
                                    border. They encountered a gang of 20, who
                                    resorted to stone pelting.
                                  </v-expansion-panel-content>

                                  <v-expansion-panel-content
                                    class="contentfont"
                                  >
                                    In the confrontation, Yadunandan lost his
                                    balance and tumbled down the hilly terrain,
                                    sustaining spinal injuries, for which he was
                                    treated at the Suretech Hospital in Nagpur.
                                  </v-expansion-panel-content>

                                  <v-expansion-panel-content class="subheading">
                                    Back to Work
                                  </v-expansion-panel-content>

                                  <v-expansion-panel-content
                                    class="contentfont"
                                  >
                                    Immediate Ex-Gratia support from WTI helped
                                    him in that difficult time. As the sole
                                    provider for his family, he expresses
                                    gratitude for the crucial financial
                                    assistance.
                                  </v-expansion-panel-content>
                                </v-expansion-panel>
                              </v-expansion-panels>
                            </v-flex>
                          </v-layout>
                        </v-card>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>

            <v-layout wrap justify-center>
              <v-flex xs11>
                <v-layout wrap justify-center pt-7>
                  <v-flex xs11>
                    <v-layout wrap justify-center>
                      <v-flex xs12>
                        <v-layout wrap>
                          <v-flex xs12 align-self-center text-center py-4>
                            <span class="mainheadig">Testimonials</span>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex xs12 lg12 py-7 v-if="icons">
                        <v-carousel
                          cycle
                          :interval="10000"
                          height="auto"
                          hide-delimiter-background
                        >
                          <v-carousel-item v-for="(item, i) in icons" :key="i">
                            <v-card outlined>
                              <v-layout
                                wrap
                                pa-2
                                justify-center
                                class="popregular"
                              >
                                <v-flex xs12>
                                  <v-layout wrap justify-center>
                                    <v-flex xs6 sm6 md2 lg2 xl2 text-center>
                                      <v-avatar size="100%">
                                        <v-img :src="item.name"></v-img>
                                      </v-avatar>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>

                                <v-flex
                                  xs10
                                  style="font-size: 17px; font-weight: bolder"
                                  text-center
                                >
                                  <v-layout wrap justify-center>
                                    <v-flex xs6 lg12 text-center>
                                      {{ item.heading }}
                                    </v-flex>
                                    <v-flex
                                      pl-3
                                      xs6
                                      lg12
                                      pt-2
                                      text-center
                                      style="color: #e27725"
                                      >{{ item.location }}</v-flex
                                    >
                                    <v-flex
                                      xs11
                                      pt-4
                                      pt-3
                                      text-center
                                      style="
                                        font-size: 14px;
                                        text-align: justify;
                                      "
                                      >{{ item.text }}</v-flex
                                    >
                                  </v-layout>
                                </v-flex>
                              </v-layout>
                            </v-card>
                          </v-carousel-item>
                        </v-carousel>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>

            <v-layout wrap justify-center pb-16 pt-10>
              <v-flex xs11 style="border: 1px solid black">
                <v-flex xs12 pt-8>
                  <span style="font-size: 17px; font-weight: bolder"
                    >Add Impact to Your Inbox
                    <br />
                    <span style="font-size: 13px">
                      Get our emails to stay in the know
                    </span>
                  </span>
                </v-flex>
                <div class="popregular" id="mc_embed_signup">
                  <form
                    action="https://wti.us20.list-manage.com/subscribe/post?u=2c47e36b829020c293c520c3f&amp;id=e29f2715de&amp;f_id=009c0deaf0"
                    method="post"
                    id="mc-embedded-subscribe-form"
                    name="mc-embedded-subscribe-form"
                    class="validate"
                    target="_blank"
                  >
                    <div id="mc_embed_signup_scroll">
                      <v-row no-gutters justfi-center>
                        <v-col
                          class="popregular"
                          cols="12"
                          xs="12"
                          sm="12"
                          md="3"
                          lg="3"
                          xl="3"
                        >
                          <v-sheet class="ma-2 pa-2">
                            <div class="mc-field-group">
                              <label for="mce-FNAME">First Name</label>
                              <input
                                style="height: 50px"
                                type="text"
                                name="FNAME"
                                class="text"
                                id="mce-FNAME"
                                required=""
                                value=""
                              />
                            </div>
                          </v-sheet>
                        </v-col>

                        <v-col
                          class="popregular"
                          cols="12"
                          xs="12"
                          sm="12"
                          md="3"
                          lg="3"
                          xl="3"
                        >
                          <v-sheet class="ma-2 pa-2">
                            <div class="mc-field-group">
                              <label for="mce-LNAME">Last Name </label
                              ><input
                                style="height: 50px"
                                type="text"
                                name="LNAME"
                                class="text"
                                id="mce-LNAME"
                                required=""
                                value=""
                              />
                            </div>
                          </v-sheet>
                        </v-col>
                        <v-col
                          class="popregular"
                          cols="12"
                          xs="12"
                          sm="12"
                          md="3"
                          lg="3"
                          xl="3"
                        >
                          <v-sheet class="ma-2 pa-2">
                            <div class="mc-field-group">
                              <label for="mce-EMAIL">Email Address</label
                              ><input
                                style="height: 50px"
                                type="email"
                                name="EMAIL"
                                class="email"
                                id="mce-EMAIL"
                                required=""
                                value=""
                              />
                            </div>
                          </v-sheet>
                        </v-col>
                        <v-col
                          class="popregular"
                          cols="12"
                          xs="12"
                          sm="12"
                          md="3"
                          lg="3"
                          xl="3"
                        >
                          <v-sheet>
                            <div class="mc-field-group">
                              <label for="mce-EMAIL" style="color: #fff"
                                >Subscribe</label
                              >
                              <input
                                type="submit"
                                name="subscribe"
                                id="mc-embedded-subscribe"
                                class="btn"
                                value="Subscribe Now"
                                style="height: 50px"
                              />
                            </div>
                          </v-sheet>
                        </v-col>
                        <div hidden="">
                          <input type="hidden" name="tags" value="3953508" />
                        </div>
                      </v-row>
                      <div id="mce-responses" class="clear">
                        <div
                          class="response"
                          id="mce-error-response"
                          style="display: none"
                        ></div>
                        <div
                          class="response"
                          id="mce-success-response"
                          style="display: none"
                        ></div>
                      </div>
                      <div
                        aria-hidden="true"
                        style="position: absolute; left: -5000px"
                      >
                        <input
                          type="text"
                          name="b_2c47e36b829020c293c520c3f_e29f2715de"
                          tabindex="-1"
                          value=""
                        />
                      </div>

                      <div class="clear"></div>
                    </div>
                  </form>
                </div>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
    <script type="text/javascript" src="//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js"></script><script type="text/javascript">
(function ($) {
  window.fnames = new Array();
  window.ftypes = new Array();
  fnames[1] = "FNAME";
  ftypes[1] = "text";
  fnames[2] = "LNAME";
  ftypes[2] = "text";
  fnames[0] = "EMAIL";
  ftypes[0] = "email";
  fnames[3] = "ADDRESS";
  ftypes[3] = "address";
  fnames[4] = "PHONE";
  ftypes[4] = "phone";
  fnames[5] = "BIRTHDAY";
  ftypes[5] = "birthday";
})(jQuery);
var $mcj = jQuery.noConflict(true);
</script>
      <script>
import RapidAction from "./RapidactionFeaturedSupport";
import axios from "axios";
export default {
  components: {
    RapidAction,
    // StoriesofImpact,
  },
  data() {
    return {
      dialog: false,

      Newaddress: "",
      Newcity: "",
      Newpincode: "",
      Newpannumber: "",
      flag: "text",
      icons: [
        {
          name: require("../assets/Images/account.jpg"),
          heading: "Emily Turner",
          location: "WTI Donor",
          text: "I'm happy to support WTI's RAP projects. They're making a tangible difference for wildlife conservation. Knowing that my donation directly aids in quick responses to wildlife emergencies is incredibly fulfilling. WTI's dedication to action and impact is why I continue to contribute.",
        },

        {
          name: require("../assets/Images/account.jpg"),
          heading: "Mark Anderson",
          location: "WTI Donor",
          text: "\"WTI's RAP projects have my full support as they stand for effective conservation. I've seen first-hand how my contributions help address critical conservation issues promptly, from human-wildlife conflict to equipping frontline forest staff. It's reassuring to know my donations are making a direct and immediate impact.\"",
        },

        {
          name: require("../assets/Images/account.jpg"),
          heading: "Sarah Davis",
          location: "WTI Donor",
          text: "\"As a long-time donor to WTI's RAP projects, I'm continually impressed by the organization's commitment to wildlife protection. These projects provide vital assistance where it's needed most, saving animals in distress and preserving ecosystems. I believe in WTI's mission and their on-ground, results-driven approach, which is why I'm a proud supporter.\"",
        },
      ],
      //inherited varibles from donation Card
      BTN1: 5000,
      BTN2: 10000,
      BTN3: 20000,
      sign: "₹",
      Cname: "INR",

      country: "India",
      amount: null,
      firstname: null,
      lastname: null,
      mobile: null,
      email: null,
      address: null,
      isFlipped: false,
      city: null,
      state: null,
      postalcode: null,
      panno: null,
      hearaboutus: "",
      comments: null,
      isanonymous: false,
      subscribe: false,
      taxExemption: false,
      symbol: "₹",
      regionssymbol: [],
      message: "",
      resource: null,
      regions: [],

      //end of inherited variables from donationCard
      rules: {
        required: (value) => !!value || "Required.",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
      donation: 5000,
      firstCard: true,
      secondCard: false,
      radio: null,
      showSnackBar: false,
      msg: "",
      ServerError: false,
      timeout: 3000,
      appLoading: false,
    };
  },
  watch: {
    country() {
      if (this.country != "India") {
        this.BTN1 = 100;
        this.BTN2 = 200;
        this.BTN3 = 500;
        this.sign = "$";
        this.Cname = "USD";
        this.donation = this.BTN1;
        //  console.log("true not india")
      } else {
        this.BTN1 = 5000;
        this.BTN2 = 10000;
        this.BTN3 = 20000;
        this.sign = "₹";
        this.Cname = "INR";

        this.donation = this.BTN1;

        //  console.log("false india")
      }
    },
  },
  beforeMount() {
    axios({
      method: "GET",
      url: "/country/all",
    })
      .then((response) => {
        this.regions = response.data.data;
      })
      .catch((err) => {
        console.log(err);
      });
    // this.getData();
  },
  methods: {
    toggleFlip() {
      this.isFlipped = !this.isFlipped;
    },
    flagClick() {
      this.flag = "noText";
      console.log("haiii");
    },
    toggleTextField() {
      // Toggle between the button and text field
      if (this.donation !== "Any other amount") {
        this.donation = "Any other amount";
      }
      // this.flag=true
    },
    handleInputChange(value) {
      // Update donation when input changes
      this.donation = value;
    },
    toTextField() {
      // Handle the logic when the button is clicked
      if (this.donation && this.donation !== "Any other amount") {
        // Handle other amounts
        console.log("Selected Amount:", this.donation);
      }
    },

    scrollToFeaturedSupport() {
      // Get the element with the ID "featuredSupportSection"
      const featuredSupportSection = document.getElementById(
        "featuredSupportSection"
      );

      // Scroll to the element with smooth behavior
      if (featuredSupportSection) {
        featuredSupportSection.scrollIntoView({ behavior: "smooth" });
      }
    },
    login() {
      this.$gtag.event("login", { method: "Google" });
    },

    validate() {
      if (this.$refs.file.validate()) {
        var don = {};
        this.appLoading = true;
        don["country"] = this.country;
        don["amount"] = this.donation;
        don["firstname"] = this.firstname;
        don["lastname"] = this.lastname;
        // don["donationtype"] = this.$route.query.DonationName;
        don["email"] = this.email;
        don["isanonymous"] = this.isanonymous;
        don["subscribe"] = this.subscribe;
        don["address"] = this.Newaddress;
        don["city"] = this.Newcity;
        // don["pincode"] = this.Newpincode;
        // don["panNumber"] = this.Newpannumber;
        don["taxExemption"] = this.taxExemption;
        don["postalcode"] = this.Newpincode;
        don["panno"] = this.Newpannumber;

        // taxExemption
        axios({
          method: "post",
          url: "/payment/initiate",
          data: don,
        })
          .then((response) => {
            if (response.data.status) {
              localStorage.setItem("currencySymbol", this.symbol);
              this.appLoading = false;
              this.$router.push(
                "/payment/?id=" +
                  response.data.id +
                  "&country=" +
                  this.country +
                  "&event=" +
                  this.$route.query.DonationName
              );
            } else {
              this.appLoading = false;
              this.msg = response.data.msg;
              this.showSnackBar = true;
            }
          })
          .catch(() => {
            this.appLoading = false;
            this.ServerError = true;
          });
      } else {
        this.showSnackBar = true;
        this.msg = "Please complete your form";
      }
    },
  },
};
</script>
      <style  scoped>
.LBL >>> label {
  font-family: poppinsregular !important;
  font-size: 12px !important;
}
.v-text-field--outlined >>> fieldset {
  border-color: grey;
  border-radius: 5px;
}

.v-radio >>> label {
  font-size: 20px;
  font-family: poppinsregular;
  color: black;
}
.center >>> input {
  text-align: center;
  font-family: poppinsregular;
  font-size: 32px;
}
.center2 >>> label {
  text-align: center;
  font-family: poppinsregular;
  font-size: 20px;
}
.center4 {
  text-align: center !important;
  font-family: poppinsregular !important;
}
.boldR >>> label {
  font-family: poppinsbold;
}
.subheading {
  font-family: poppinsregular;
  font-weight: bold;
  font-size: 16px;
  color: #3d673a;
}
.mainheadig {
  font-family: poppinsregular;
  font-weight: bold;
  font-size: 20px;
  color: black;
}

.contentfont {
  font-family: poppinsregular;
  font-size: 14px;
  color: black;
}
.animate {
  position: relative;
  animation: mymove 0.25s;
}
.animate2 {
  position: relative;
  animation: mymove2 0.25s;
}
#div2 {
  animation-timing-function: linear;
}
@keyframes mymove2 {
  from {
    left: -200px;
  }
  to {
    left: 0px;
  }
}

@keyframes mymove {
  from {
    right: -500px;
  }
  to {
    right: 0px;
  }
}
#div1 {
  animation-timing-function: linear;
}

.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear; /* Safari */
  animation: spin 2s linear;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.v-checkbox >>> label {
  font-family: poppinsregular;
}
</style>